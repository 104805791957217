import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { validationMessage } from '../../core/pure-utils/validators';

/** Make relative urls obsolute.  This can be removed when classic app goes away */
@Pipe({
  name: 'validationMessage'
})
export class ValidationMessagePipe implements PipeTransform {
  transform(validationErrors: ValidationErrors, ...args: any[]): string {
    return validationMessage(validationErrors);
  }
}
