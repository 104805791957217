<h1 matDialogTitle class="mat-headline-5 notification-header">Notifications</h1>
<div class="loading-shell">
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="userMessages.length !== 0">
    <div class="message-display-shell">
        <mat-card appearance="raised" *ngFor="let message of userMessages; let index = index">
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                    <mat-panel-title>
                        <mat-icon fontSet="fa" fontIcon="fa-bullhorn" class="card-icon"></mat-icon>
                        <h2 *ngIf="message.status !== 'accepted'" class="mat-subtitle-1">{{message.notifications_messages.notifications_title}}</h2>
                        <h2 *ngIf="message.status === 'accepted'" class="mat-subtitle-1 fade">{{message.notifications_messages.notifications_title}}</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="notification-view" [innerHTML]="message.notifications_messages.notifications_body | trustHtml"></div>
            </mat-expansion-panel>

            <div class="button-shell">
                <button #button *ngIf="message.status !== 'accepted'" mat-raised-button class="green medium" (click)="markAsRead(message.notifications_id, index)" style="float: right;">Got It</button>
            </div>
        </mat-card>
    </div>
</div>

<div *ngIf="userMessages.length === 0 && !loading" class="no-notification-display">
    <p class='mat-body-2'>There are currently no notifications for you.</p>
    <div>
        <button mat-raised-button class="green medium" [matDialogClose]="true">Ok</button>
    </div>
</div>
