import {
  ChangeDetectorRef,
  Component,
  Input, OnInit,
  Optional
} from '@angular/core';
import {Observable, of } from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {DashboardPermissionsService} from '../../../dashboard/dashboard-permissions-service/dashboard-permissions.service';
import {DashboardApiService} from '../../../core/services/api/dashboard-api.service';
import {EntityGroupName, Network} from '../../../core/dtos/api';
import {RoutingService} from '../../../core/services/routing.service';
import { MerchantApiService } from '../../../core/services/api/merchant-api.service';

@Component({
  selector: 'app-header-sub-nav',
  templateUrl: './header-sub-nav.component.html',
  styleUrls: ['./header-sub-nav.component.scss']
})
export class HeaderSubNavComponent implements OnInit{
  @Input() headerTitle: string;

  public readonly subHeaderGroupings = {
    report: [
      '/admin/reports.php',
      '/merchant_all/reports.php',
      '/affiliate/reports.php',
      '/report/performance',
      '/report/avantmetrics'
    ],
    reports: [
      '/reports/performance',
      '/reports/all-reports'
    ],
    userSettings: [
      '/user-settings/profile',
      '/user-settings/password',
      '/user-settings/arches-settings',
      '/user-settings/security',
      '/user-settings/email-subscriptions',
      '/affiliate/notifications.php',
      '/merchant_all/notifications.php'
    ],
    accountSettings: [
      '/account-settings/account-profile',
      '/account-settings/manage-users',
      '/account-settings/avantlink-networks',
      '/affiliate/edit_websites.php',
      '/affiliate/tag_group_admin.php',
      '/merchant_all/edit_program_terms.php',
      '/account-settings/email-templates',
      '/merchant_all/contract_list.php',
      '/account-settings/integration',
      '/merchant_all/order_restrict_ip.php'
    ],
    adTools: [
      '/ad-tools'
    ],
    adminEmailTemplates: [
      '/admin-tools/email-templates'
    ],
    adManagement: [
      '/ad-tools/ad-management'
    ],
    customLinkBuilder: [
      '/ad-tools/custom-link-builder',
      '/ad-tools/custom-link-builder/merchant',
      '/ad-tools/custom-link-builder/affiliate'
    ],
    discounts: [
      '/ad-tools/discounts'
    ],
    easyLink: [
      '/ad-tools/easy-link'
    ],
    productLink: [
      '/ad-tools/product-link'
    ],
    qrCode: [
      '/ad-tools/qr-code'
    ],
    notificationManagement: [
      '/notifications/management'
    ],
    accessDenied: [
      '/access-denied'
    ],
    '404Page': [
      '/404'
    ],
    affiliateDashboard: [
      '/dashboard/affiliate'
    ],
    merchantDashboard: [
      '/dashboard/merchant'
    ],
    merchantTools: [
      '/admin/merchant-tools/integration-form'
    ],
    agencyDashboard: [
      '/dashboard/agency'
    ],
    adminDashboard: [
      '/dashboard/admin',
      '/dashboard/key-accounts'
    ],
    datafeedManagement: [
      '/affiliate/configure_datafeed.php',
      '/affiliate/edit_services.php',
      '/affiliate/edit_image_preferences.php'
    ],
    apiManagement: [
      '/affiliate/api_request_builder.php',
      '/affiliate/view_edit_auth_key.php'
    ],
    orderInquiries: [
      '/affiliate/order_inquiry_list.php',
      '/affiliate/order_inquiry_batch.php',
      '/affiliate/tracking_parser.php'
    ],
    paymentSettings: [
      '/affiliate/payout_history.php',
      '/affiliate/payout_tipalti.php',
      '/affiliate/withdraw_now.php',
      '/merchant_all/view_invoice_list.php',
      '/merchant_all/ebp_payment.php',
      '/merchant_all/pay_invoices.php',
      '/merchant_all/ebp_payment_credit.php',
      '/merchant_all/ebp_setup.php'
    ],
    commissionTools: [
      '/merchant_all/affiliate_bonus.php',
      '/merchant_all/affiliate_commission_promotion.php',
      '/merchant_all/tag_group_admin.php',
      '/merchant_all/network_settings.php',
      '/merchant_all/sub_affiliate_commission.php',
      '/merchant_all/item_based_commission.php'
    ],
    transactionManager: [
      '/merchant_all/order_select.php',
      '/merchant_all/order_manual_entry.php',
      '/transaction-manager/batch-returns-sales',
      '/merchant_all/order_inquiry_list.php',
      '/merchant_all/order_search.php',
      '/merchant_all/tracking_parser.php'
    ],
    paidPlacement: [
      '/merchant_all/ppb.php',
      '/merchant_all/ppb_list.php',
      '/merchant_all/paid_placement.php',
      '/affiliate/ppb_configure.php',
      '/affiliate/ppb_list.php',
      '/affiliate/ppb_bids.php',
      '/affiliate/ppb_source.php',
      '/affiliate/ppb_schedule.php'
    ],
    linkEncoder: [
      '/affiliate/affiliate_link_encoder_list.php',
      '/affiliate/affiliate_link_encoder.php',
      '/affiliate/affiliate_link_encoder_source.php'
    ],
    dynamicDealFeed: [
      '/affiliate/coupon_feed_signup.php',
      '/affiliate/coupon_feed_edit.php',
      '/affiliate/coupon_feed_source.php'
    ],
    productAdWidget: [
      '/affiliate/product_ad_widget.php',
      '/affiliate/paw_edit.php'
    ],
    productContentWidget: [
      '/affiliate/pcw.php',
      '/affiliate/pcw_list.php',
      '/affiliate/pcw_source.php'
    ],
    moderation: [
      '/admin-tools/email-moderation',
      '/admin/moderation_queue.php'
    ],
    partners: [
      '/partners/email-partners'
    ],
    communication: [
        '/communication-tools/email-moderation',
        '/communication-tools/notifications',
        '/communication-tools/email-templates',
        '/communication-tools/network-emails',
        '/admin/moderation_queue.php'
    ]
  };

  public environment = environment;
  public subHeaderGrouping$: Observable<string>;
  readonly twoFactorPathRegex = /\/affiliate\/authy.php/;
  public pagePathName: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dashboardPermissionsService: DashboardPermissionsService,
    private dashboardApiService: DashboardApiService,
    public routingService: RoutingService,
    public merchantApiService: MerchantApiService,
    @Optional() private router: Router
  ) { }

  currentAccountEntityGroupName: EntityGroupName;
  currentAccountEntityGroupName$ = this.dashboardPermissionsService.accountWithPermissions$.pipe(
    map(([currentAccount, ]) => currentAccount.entity_group_name)
  );
  currentAccountEntityGroupName$Sub = this.currentAccountEntityGroupName$.subscribe(x => this.currentAccountEntityGroupName = x);

  currentAccountEntityNetwork: Network;
  currentAccountEntityNetwork$ = this.dashboardPermissionsService.accountWithPermissions$.pipe(
    map(([currentAccount, ]) => currentAccount.entity_network)
  );
  currentAccountEntityNetwork$Sub = this.currentAccountEntityNetwork$.subscribe(x => this.currentAccountEntityNetwork = x);

  hasMultipleMerchants: boolean;
  hasMultipleMerchants$ = this.dashboardApiService.availableAccounts$.pipe(
    map(availableAccounts => {
      let hasOneOrMoreMerchantGroupAccounts = availableAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchant_groups').length >= 1;
      let standAloneMerchantCount = availableAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchants').length;
      return standAloneMerchantCount > 1 || (standAloneMerchantCount === 1 && hasOneOrMoreMerchantGroupAccounts);
    })
  );
  hasMultipleMerchants$Sub = this.hasMultipleMerchants$.subscribe(x => this.hasMultipleMerchants = x);

  permissions: {[key: string]: boolean} = {};
  permissions$ = this.dashboardPermissionsService.accountWithPermissions$.pipe(
    map(([, permissions]) => this.dashboardPermissionsService.createPermissionLookUpArray(permissions))
  );
  permissions$Sub = this.permissions$.subscribe(x => this.permissions = x);

  location = window.location;

  ngOnInit(): void {
    if (environment.isClassic) {
      // A couple of classic pages redirect to a two authentication page. In a case the user is redirected to that page we
      // need to use the redirect query string to determine subheaders and active subheader styling.
      this.pagePathName = window.location.pathname.match(this.twoFactorPathRegex) ? decodeURI((new URL(window.location.href).searchParams).get('redirect'))
        : window.location.pathname;

      this.subHeaderGrouping$ = of(this.pagePathName).pipe(
        map((pathname) => {
          let sanitizedCurrentRoute = pathname.split(';')[0];
          let returnedClassicSubNav = 'standardClassic';
          for (let page in this.subHeaderGroupings) {
            if (this.subHeaderGroupings[page].some(path => path === sanitizedCurrentRoute)) {
              returnedClassicSubNav = page;
            }
          }
          return returnedClassicSubNav;
        })
      );
    } else {
      this.subHeaderGrouping$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(''),
        map(() => {
          let returnedClassicSubNav = 'standardClassic';
          for (let page in this.subHeaderGroupings) {
            if (this.subHeaderGroupings[page].some(path => path === window.location.pathname)) {
              returnedClassicSubNav = page;
            }
          }
          return returnedClassicSubNav;
        })
      );
    }
  }
}
