<div class="form-container">
  <form class="login-form" (ngSubmit)="login()" #loginForm="ngForm">
    <app-logo-link></app-logo-link>
    <p class="message">
      {{message}}
    </p>
    <div class="container">
      <mat-form-field subscriptSizing="fixed">
        <input matInput name="email" type="email" required email placeholder="Email" [(ngModel)]="emailAddress"
          (keydown)="checkCapsLock($event)" (keyup)="checkCapsLock($event)">
        <mat-icon matPrefix>email</mat-icon>
        <mat-error>Please enter a valid email.</mat-error>
      </mat-form-field>
    </div>
    <div class="container">
      <mat-form-field subscriptSizing="fixed">
        <input matInput name="password" type="password" required placeholder="Password"
             [(ngModel)]="password" (keydown)="checkCapsLock($event)" (keyup)="checkCapsLock($event)" #passwordElement="ngModel">
        <mat-icon matPrefix>lock</mat-icon>
        <mat-error>{{passwordElement.errors | validationMessage}}</mat-error>
      </mat-form-field>
      <div>
        <a [routerLink]="['/login/forgot-password']">
          Forgot password?
        </a>
      </div>
      <mat-chip-listbox>
        <mat-chip class="red error-chip" selected="true" *ngIf="(isCapsLockOn | async)">Caps Lock is on!</mat-chip>
      </mat-chip-listbox>
    </div>
    <div class="container">
      <button color="primary" type="submit" mat-raised-button [disabled]="!loginForm.valid || loginForm.pristine || disableLoginButton">
        Login
      </button>
    </div>
  </form>
  <div>
    <p>
      <span>Don't have an account? </span>
      <br/>
      <a href="https://www.avantlink.com/signup/">Get started with AvantLink now.</a>
    </p>
  </div>
</div>
