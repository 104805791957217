import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { Network, AvailableCurrency, Uuid } from '../dtos/api';
import { traverse } from './object-utils';
import {pipe} from 'rxjs';
import {addHours, addMinutes} from 'date-fns/fp';

/*
 * All functions within the pure-utils directiory should be pure.
 * Pure means the function only uses its parameters for input (no outside state) and has no side effects (no mutation of parameters or outside state).
 */

export const clientSideIdLength = 15;

/**
 * Removes the the http protocol and 'www' from a url string.
 */
export function stripHttpWWWFromUrl(url: string): string {
  if (url == null) {
    return url;
  }
  return url.replace(/^(https?:\/\/)?(www\.)?/, '');
}


export function parseNumber(numberString: string) {
  if (numberString == null) {
    return <number> <any> numberString;
  }
  return parseFloat(numberString.replace(/[^\d.-]/g, ''));
}

export function getHostnameFromUrl(url: string, tolerantToMissingProtocol = false): string {
  if (tolerantToMissingProtocol && !url.startsWith('http')) {
    // add missing protocl so parse works correctly
    url = 'http://' + url;
  }

  let a  = document.createElement('a');
  a.href = url;
  return a.hostname;
}

export function networkToCurrencyCode(countryCode: Network): AvailableCurrency {
  return countryCode === 'US' ? 'USD' :
    countryCode === 'CA' ? 'CAD' :
    countryCode === 'AU' ? 'AUD' : null;
}

export function ordinalNumeral(int: number) {
  let lastDigit = int % 10;
  let last2Digits = int % 100;

  if (4 <= last2Digits && last2Digits <= 20) {
    return int + 'th';
  }

  if (lastDigit === 1) {
    return int + 'st';
  } else if (lastDigit === 2) {
    return int + 'nd';
  } else if (lastDigit === 3) {
    return int + 'rd';
  } else {
    return int + 'th';
  }
}


/**
 * Creates a new random string of characters for use as a unique client side id.
 */
export function newClientSideId(): string {
  return ('CID-' + Math.random().toString(32).slice(2, 13)).padEnd(clientSideIdLength, '0');
}

export function removeAppendedClientSideId(theString: string): string {
  return theString.slice(0, -clientSideIdLength);
}

export const uuid = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;

export function isUuid(value: string): value is Uuid {
  return typeof value === 'string' && uuid.test(value);
}

/**
 * This parses a time string and returns a date.
 * @param passedDate
 * @param passedTime
 */
export function addDateAndTime(passedDate: Date, passedTime: string) {
  let [timeHours, timeMinutes] = passedTime.split(':').map(x => parseInt(x, 10));
  return pipe(addHours(timeHours), addMinutes(timeMinutes))(passedDate);
}

/**
 * Returns a modified date (add or subtracts time) so that the date when stored will be in mountain time.
 * Use before sending user date inputs to the server.
 */
export function mtTimeToUtc(date: Date | string | number) {
  if (date == null) {
    return <Date> date;
  }
  return fromZonedTime(date, 'America/Denver');
}

/**
 * Returns a modified date (add or subtracts time) so the browser displays the date in mountain time. For when populating date inputs and pickers.
 * This differs from the dateIntl pipe which is a simpler method that just formats the Date to a Mountain Time string. Do not use together.
 */
export function utcToMtTime(date: Date | string | number) {
  if (date == null) {
    return <Date> date;
  }
  return toZonedTime(date, 'America/Denver');
}

export const iso8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;

/**
 * Convert all ISO8601 date strings in an object to a Mountain Time Date
 */
export function deepMutateIsoToMtTime(object: any) {
  traverse(object, (currentObject, propName, value) => {
    if  (typeof value === 'string' && iso8601.test(value)) {
      currentObject[propName] = utcToMtTime(value);
    }
  });
}

/**
 * This truncates a string with the option to add ellipsis.
 */
export function truncateString(inputString: string, length: number, addEllipsis = false) {
  if (!inputString || typeof inputString !== 'string') {
    return '';
  }
  let resultString = inputString.slice(0, length);
  if (addEllipsis && resultString.length === length) {
    resultString += '...';
  }
  return resultString;
}
