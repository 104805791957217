import { NgModule } from '@angular/core';
import { AuthyApiModule } from '../api-services/authy-api/authy-api.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LoginComponent } from './login/login.component';
import { LoginStateService } from './login-state-service/login-state.service';
import { DeviceService } from './device-service/device.service';
import { LoginRoutingModule } from './login-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetupPhoneComponent } from './setup-phone/setup-phone.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterDeviceComponent } from './register-device/register-device.component';
import { VerifyDeviceComponent } from './verify-device/verify-device.component';
import { LogoLinkComponent } from './logo-link/logo-link.component';
import { GreetingService } from './greeting/greeting.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    LoginRoutingModule,
    AuthyApiModule,
    DashboardModule,
    SharedModule
  ],
  exports: [
    LoginComponent,
    ForgotPasswordComponent,
    RegisterDeviceComponent,
    ResetPasswordComponent,
    SetupPhoneComponent,
    VerifyDeviceComponent,
    VerifyPhoneComponent
  ],
  declarations: [
    LoginContainerComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetupPhoneComponent,
    VerifyPhoneComponent,
    ResetPasswordComponent,
    RegisterDeviceComponent,
    VerifyDeviceComponent,
    LogoLinkComponent
  ],
  providers: [
    LoginStateService,
    DeviceService,
    GreetingService,
  ]
})
export class LoginModule { }
export { DeviceService } from './device-service/device.service';
