import {Component, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-persistent-snack-bar',
  templateUrl: './persistent-snack-bar.component.html',
  styleUrl: './persistent-snack-bar.component.scss'
})
export class PersistentSnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);
  message = inject(MAT_SNACK_BAR_DATA);
}
